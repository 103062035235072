import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import Layout from '../../../../components/Layout'
import UniversityExplorerComponent from '../../../../universityExplorer'
import UEHeader from '../../../../universityExplorer/UEHeader'
import Sidebar from '../../../../universityExplorer/Sidebar'
import mixpanel from 'mixpanel-browser'
import './index.sass'

import ToolsTabs from '../../../../components/marketplace/tools/ToolsTabs'

// manage filters here probably?
// do we want to do filters on the frontend?
// yes, that way filtering is always fast right?
// hmm. lets figure that out after we just at least get fujio set up with
// building the frontend.

export default class UniversityExplorerPage extends React.Component {
  constructor(props) {
    super()

    const {
      data: {
        allSchoolMetadata: { edges },
      },
    } = props

    const schools = _.map(edges, 'node')

    this.state = {
      schoolsByUnitId: schools.reduce((acc, school) => ({ ...acc, [school.unitid]: school }), {}),
      filters: [],
      filterSubjects: [],
    }
  }

  resetFilters = () => {
    this.setState({ filters: [] })
  }

  attachSchoolInfoToUnitIDs = rows => {
    const { schoolsByUnitId } = this.state
    return rows.map(row => ({
      ...row,
      ...schoolsByUnitId[row.unitid],
    }))
  }

  toggleFilter = async (filter, title, added) => {
    ;(await this.state.filters.find(selected => selected._id === filter._id))
      ? this.setState({
          filters: this.state.filters.filter(selected => selected._id !== filter._id),
        })
      : this.setState({ filters: this.state.filters.concat([filter]) })

    // Check if the filter was added or removed
    if (added) {
      mixpanel.track('ri:ue-filters', {
        // All currently active filter labels
        allFilters: this.state.filters.map(filter => {
          return filter.label
        }),

        // Just activated filter
        activatedFilter: filter.label,

        // Just activated filter type
        activatedFilterType: title,
      })
    }
  }

  setFilter = filter =>
    this.setState({
      filters: this.state.filters.filter(selected => selected._id !== filter._id).concat(filter),
    })

  removeFilter = filter =>
    this.setState({ filters: this.state.filters.filter(selected => selected._id !== filter._id) })

  render() {
    const { filters } = this.state
    return (
      <Layout noFooter active="tools" requireAuth>
        <ToolsTabs active="university-explorer" />
        <div className="universityExplorer">
          <UEHeader />
          <div className="columns is-gapless">
            <div className="column is-one-quarter">
              <Sidebar
                selectedFilters={this.state.filters}
                toggleFilter={this.toggleFilter}
                setFilter={this.setFilter}
                removeFilter={this.removeFilter}
                resetFilters={this.resetFilters}
              />
            </div>
            <div className="column is-clipped">
              <UniversityExplorerComponent
                // data={data}
                attachSchoolInfoToUnitIDs={this.attachSchoolInfoToUnitIDs}
                // groups={groups}
                // selectedCategory={selectedGroup}
                cipCodes={['15_1201', '15_1202']}
                filters={filters.map(filter => filter.filter)}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const UEPageQuery = graphql`
  query UniversityExplorerQuery {
    allSchoolMetadata {
      edges {
        node {
          unitid
          usBased
          institutionName
          institutionNameAlias
          usNewsRanking
          top100Liberal
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`
